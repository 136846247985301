<template>
  <div class="main-container">
    <div class="general-container">
      <span v-if="this.winner" class="winner-class"></span>
      <img
        src="../../../public/assets/raffle/leftArrow.svg"
        class="left-arrow"
      />
      <img
        src="../../../public/assets/raffle/rightArrow.svg"
        class="right-arrow"
      />

      <ul class="rafflePlayerList">
        <li
          v-for="(player, index) in playersAnotados"
          :key="player.dbid + index"
          class="rafflePlayer"
        >
          <div class="rafflePlayerAvatar">
            <player-avatar :player="player" :scale="2.5"></player-avatar>
          </div>
          <p>{{ player.username }}</p>
        </li>

        <article class="user rafflePlayer skeleton" v-for="i in media" :key="i">
          <figure class="rafflePlayerAvatar">
            <p class="">
              <b-skeleton circle width="50px" height="50px"></b-skeleton>
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <p>
                <b-skeleton height="20px" width="200px"></b-skeleton>
              </p>
            </div>
          </div>
        </article>
      </ul>
    </div>

    <qr-box />

    <div class="card-container">
      <div class="card-image">
        <img :src="this.options.imageRaffle.url" alt="" />
      </div>

      <div class="card-description">
        <h3>{{ $t("exp_raffle.screenTitle") }}</h3>
        <p>{{ $t("exp_raffle.screenDescription") }}</p>
      </div>
    </div>

    <div class="new-users">
      <transition-group
        appear
        @before-enter="moderatedBeforeEnter"
        @enter="moderatedEnter"
        @leave="moderatedLeave"
      >
        <div
          v-for="user in newUsersList"
          class="new-user"
          :style="{ border: '3px solid #' + user?.color }"
          :key="user.dbid"
        >
          <player-avatar :player="user" :scale="2.5" class="new-user-avatar" />
          <div class="new-user-name">
            <h4>{{ user?.username }}</h4>
            <p>{{ $t("exp_raffle.screenNotification") }}</p>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import "./screenRaffle.scss";
import { RAFFLE_STATES, TOUR_LEADER } from "../constants";
import axios from "axios";
import { removeElement, shuffleArray } from "../utils";
import qrBox from "./qrBox.vue";
import gsap from "gsap";

export default {
  //TODO: falta enviar un estado NOT_READY cuando se desmonta el componente
  components: {
    qrBox,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    console.log(this.options);
  },
  data() {
    return {
      RAFFLE_STATES: RAFFLE_STATES,
      winner: null,
      state: null, // NOT_READY, WAITING_FOR_PLAYERS, RUNNING, COMPLETED
      playersAnotados: [],
      winners: [],
      queuedPlayers: [],
      newUsersList: [], //lista de pop ups
      media: 9, // Cantidad de skeletons
    };
  },
  watch: {
    state(newState) {
      this.$socket.client.emit("roomPlayersMessage", {
        type: "currentStatus",
        currentStatus: newState,
      });
    },
    immediate: true,
  },
  methods: {
    changeRaffleState(newState) {
      this.state = newState;
    },
    async getWinners() {
      await axios
        .get(process.env.VUE_APP_API_BASE + "/api/rafflewinners/?limit=999")
        .then((res) => {
          this.winners = res.data.docs;
        })
        .catch((err) => {
          throw err;
        });
    },
    moderatedBeforeEnter(el) {
      el.style.transform = "translateX(500px)";
      el.style.opacity = "0";
    },
    moderatedEnter(el) {
      gsap.to(el, {
        x: "0px",
        duration: 0.5,
        ease: "power1.out",
        opacity: "1",
      });
    },
    moderatedLeave(el) {
      gsap.to(el, {
        x: "1000px",
        duration: 0.5,
        ease: "power1.out",
        opacity: "0",
      });
    },
    popUpLeave(user) {
      setTimeout(() => {
        const updatedList = [...this.newUsersList].filter(
          (a) => a.dbid !== user.dbid
        );
        this.newUsersList = updatedList;
      }, 5000);
    },
  },

  sockets: {
    playerJoined(user) {
      if (user.role !== TOUR_LEADER) {
        this.$socket.client.emit("messageToPlayer", {
          to: user.socketid,
          msg: "currentStatus",
          data: { currentStatus: this.state },
        });

        const isInPlayerAnotados = this.playersAnotados.some(
          (player) => player.dbid == user.dbid
        );
        const isInQueuedPlayers = this.queuedPlayers.some(
          (player) => player.dbid == user.dbid
        );
        const isWinner = this.winners.some(
          (winner) => winner.user.id === user.dbid
        );
        const isRunning = this.state === RAFFLE_STATES.RUNNING;
        const isCompleted = this.state === RAFFLE_STATES.COMPLETED;
        const isWaitingForPlayers =
          this.state === RAFFLE_STATES.WAITING_FOR_PLAYERS;

        if (!isInPlayerAnotados && !isWinner) {
          if (isWaitingForPlayers) {
            this.playersAnotados.push(user);

            //TODO: La animacion tmb se debe ejecutar cuando se reinicia el sorteo y entran todos los queuedPlayers
            this.newUsersList.unshift(user); // Lista de pop ups
            this.popUpLeave(user); // Salida de pop ups
          } else if ((isRunning || isCompleted) && !isInQueuedPlayers) {
            this.queuedPlayers.push(user);
          }
        }
      }
    },
    async playerList() {
      await this.getWinners();
      this.state = RAFFLE_STATES.WAITING_FOR_PLAYERS;
      this.playersAnotados = this.$store.state.space.players.reduce(
        //chequea que los usuarios conectados no hayan ganado previamente
        (acc, curr) => {
          if (!this.winners.some((winner) => winner.user.id === curr.dbid)) {
            if (curr.role !== TOUR_LEADER) {
              //Y que no sean tour leader
              acc.push(curr);
            }
            return acc;
          } else return acc;
        },
        []
      );
    },

    onTrigger() {
      this.state = RAFFLE_STATES.RUNNING;
      this.playersAnotados = shuffleArray(this.playersAnotados);
      setTimeout(async () => {
        this.winner =
          this.playersAnotados[
            Math.floor(Math.random() * this.playersAnotados.length)
          ];
        this.state = RAFFLE_STATES.COMPLETED;
        this.$socket.client.emit("roomPlayersMessage", {
          type: "winner",
          winnerId: this.winner.dbid,
        });

        await axios
          .post(process.env.VUE_APP_API_BASE + "/api/rafflewinners", {
            user: this.winner.dbid,
          })
          .catch((err) => {
            throw err;
          });
      }, 5000);
    },
    onRaffleReset() {
      this.changeRaffleState(RAFFLE_STATES.WAITING_FOR_PLAYERS);
      const newPlayersAnotados = removeElement(
        this.playersAnotados,
        this.winner.dbid
      );

      const filteredQueue = this.queuedPlayers.reduce((acc, curr) => {
        if (!newPlayersAnotados.some((player) => player.dbid === curr.dbid)) {
          acc.push(curr);
          return acc;
        } else return acc;
      }, []);

      this.playersAnotados = [...newPlayersAnotados, ...filteredQueue];

      this.queuedPlayers = [];

      this.getWinners();
    },
    onDisableRaffle() {
      this.changeRaffleState(RAFFLE_STATES.NOT_READY); //TODO: falta chequear cuando se vuelve a habilitar que vuelva a meter a los players
    },
  },
};
</script>
